import React from "react";
import { ModalButton } from "../../../components/Modal";

import ModalForm from "./ModalForm";

function ModalDocsUpload({ quote, onSuccess }) {
    return (
        <ModalButton title="Anexar arquivos" buttonContent="Anexar documentos">
            <ModalForm quote={quote} onSuccess={onSuccess} />
        </ModalButton>
    );
}
export default ModalDocsUpload;
